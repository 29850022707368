import React, {useEffect} from 'react';
import Navbar from './components/Navbar';
import Home4 from './components/Home4';
import AboutSection from './components/AboutSection';
import RobotSection from "./components/RobotSection";
import FeatureSection from './components/FeatureSection';
import PricingSection from './components/PricingSection';
import PartnerSection from "./components/PartnerSection";
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt';
import * as mixpanel from "mixpanel-browser";
import AwardsSection from "./components/AwardsSection";

const Main = () => {
    useEffect(() => {
        document.getElementById("main_navbar").classList.add("navbar-light");
        mixpanel.init(
            process.env.REACT_APP_MIXPANEL_TOKEN,
            {host: process.env.REACT_APP_MIXPANEL_URL},
            '',
        );
        mixpanel.track_links("#main_navbar a", "click nav link", {
            "Referrer": document.referrer
        });
        mixpanel.track('landing-page-view')
    }, [])

    return (
        <React.Fragment>
            <Navbar />
            <Home4 />
            {/*<ContactSection />*/}
            <AboutSection />
            <RobotSection />
            <FeatureSection />
            <PartnerSection />
            <PricingSection />
            <AwardsSection />
            <Footer />
            <FooterAlt />
        </React.Fragment>
    );

}

export default Main;