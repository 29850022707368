import React, {useEffect, useState} from 'react';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {Col, Row} from 'reactstrap';
import {gql, useMutation} from "@apollo/client";
import * as mixpanel from "mixpanel-browser";
import ReCAPTCHA from "react-google-recaptcha";


const SENDMAIL_MUTATION = gql`mutation SendEmailMessage($sendEmailInput: SendEmailInput!) {
    sendEmailMessage(sendEmailInput: $sendEmailInput)
}`

const ContactSection = () => {
    const recaptchaRef = React.createRef();
    const [messageSent, setMessageSent] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [sendEmail, {data, loading, error}] = useMutation(SENDMAIL_MUTATION);
    const [captureComplete, setCaptureComplete] = useState(false);

    useEffect(() => {
        if (!error && data) {
            setMessageSent(true);
        }
        if (error) {
            setHasError(true);
        }
    }, [data, error])

    const handleSubmit = async (event, errors, values) => {
        try {
            mixpanel.track('landing-page:contact-us', {
                ...values
            });
        } catch (e) {
            console.error('MIXPANEL-FAILED', e);
        }
        setCaptureComplete(false);
        const {name, email, subject = 'Email Sign Up', comments = 'Sign up'} = values;
        mixpanel.identify(email);
        mixpanel.people.set({
            $first_name: `${name}`.split(' ')[0],
            $last_name: `${name}`.split(' ')[1] || '',
            $name: name,
            $email: email, // only reserved properties need the $
            'Sign up date': new Date().toISOString(),
            'Most Recent Login': new Date().toISOString(),
            USER_ID: email,
        });
        mixpanel.track('contact-sign-up', {email})
        const variables = {
            sendEmailInput: {
                name,
                email,
                message: comments,
                subject: subject
            }
        }
        await sendEmail({
            variables
        });
    }

    return (
        <React.Fragment>
            <section className="section" id="contact">
                <div className="container">
                    {/*<div className="row">*/}
                    {/*    <Col lg="12">*/}
                    {/*        <div className="title-heading mb-5">*/}
                    {/*            <h3 className="text-dark mb-1 font-weight-light text-uppercase">Sign Up for more Grant Information</h3>*/}
                    {/*            <div className="title-border-simple position-relative"></div>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*</div>*/}

                    <Row>
                        <Col lg="12">
                            <div className="contact-box p-5 bg-light">
                                <Row>
                                    <Col lg="12">
                                        <h2 className="font-weight-light">Grant Alerts! Sign-up</h2>
                                        <p className="text-muted">Enter your name and business email below to receive the latest grant news, as we roll out the service.</p>
                                        <div className="custom-form p-3">
                                            {messageSent &&
                                                <div id="message">
                                                    <div className="features-icon" style={{
                                                        fontSize: '6rem',
                                                        textAlign: 'center',
                                                    }}>
                                                        <i className="pe-7s-check text-success"></i>
                                                    </div>
                                                    <h1 style={{
                                                        textAlign: 'center',
                                                        fontWeight: '300'
                                                    }}>
                                                        Subscription Added
                                                    </h1>
                                                    {/*<div style={{textAlign: 'center', marginTop: '1rem'}}>*/}
                                                    {/*    <input disabled={loading} type="button"*/}
                                                    {/*           className="btn btn-sm"*/}
                                                    {/*           value="Send Another"*/}
                                                    {/*           onClick={() => setMessageSent(false)}*/}
                                                    {/*    />*/}
                                                    {/*</div>*/}
                                                </div>
                                            }
                                            {hasError &&
                                                <div id="message">
                                                    <div className="features-icon" style={{
                                                        fontSize: '6rem',
                                                        textAlign: 'center',
                                                    }}>
                                                        <i className="pe-7s-close-circle text-danger"></i>
                                                    </div>
                                                    <h1 style={{
                                                        textAlign: 'center',
                                                        fontWeight: '300'
                                                    }}>
                                                        Sorry, there was an error
                                                    </h1>
                                                    <p style={{textAlign: 'center'}}>
                                                        Please try again or email <a
                                                        href="mailto:support@fundify.org.uk">support@fundify.com</a> for
                                                        help</p>
                                                </div>
                                            }
                                            {!messageSent &&
                                                <AvForm id="contact-form" onSubmit={handleSubmit}>
                                                    <Row>
                                                        <Col lg="12">
                                                            <div className="form-group app-label">
                                                                <AvField name="name" type="text"
                                                                         validate={{required: {value: true}}}
                                                                         placeholder="Name" />
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <div className="form-group app-label">
                                                                <AvField name="email" type="text"
                                                                         errorMessage="Invalid Email" validate={{
                                                                    required: {value: true},
                                                                    email: {value: true}
                                                                }} placeholder="Business Email" />
                                                            </div>
                                                        </Col>
                                                        <Col sm="12" style={{marginBottom: '1.4rem'}}>
                                                            <ReCAPTCHA
                                                                ref={recaptchaRef}
                                                                sitekey={process.env.REACT_APP_CAPTCHA_PUBLIC}
                                                                onChange={() => setCaptureComplete(true)}
                                                            />
                                                        </Col>
                                                        <Col lg="12" style={{ marginBottom: '1.2rem' }}>
                                                            <input style={{ width: '100%'}} disabled={!captureComplete || loading}
                                                                   type="submit"
                                                                   className="submitBnt btn btn-custom"
                                                                   value="Sign Up" />
                                                        </Col>
                                                        {/*<Col lg="12">*/}
                                                        {/*    <div className="form-group app-label">*/}
                                                        {/*        <AvField name="subject" type="text"*/}
                                                        {/*                 validate={{required: {value: true}}}*/}
                                                        {/*                 placeholder="Subject" />*/}
                                                        {/*    </div>*/}
                                                        {/*</Col>*/}
                                                        {/*<Col lg="12">*/}
                                                        {/*    <div className="form-group app-label">*/}
                                                        {/*        <AvField name="comments" type="textarea"*/}
                                                        {/*                 validate={{required: {value: true}}} rows="5"*/}
                                                        {/*                 placeholder="Message" />*/}
                                                        {/*    </div>*/}
                                                        {/*</Col>*/}
                                                        <Row>
                                                            <Col sm="12">
                                                                <div id="simple-msg"></div>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                </AvForm>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </React.Fragment>
    );

}
export default ContactSection;