import React from 'react';
import {Col, Row} from 'reactstrap';

class FeatureSection extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="section bg-features bg-light" id="features">
                    <div className="container">
                        <Row>
                            <Col className="col-lg-12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Our Features</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col lg="6" style={{ marginBottom: '3rem'}}>
                                <div className="features-content">
                                    <div className="features-icon">
                                        <i className="pe-7s-search text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Targeted Searching</h4>
                                    <p className="text-muted f-14">Using modern data techniques and years of in-industry research Fundify has built a growing platform that allows for easy searching and targeting of prospects, reducing the pain and frustration caused by old scattered data.</p>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-img mt-32">
                                    <img src="images/features-img/headphones_in_typing_small.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="section bg-features">
                    <div className="container">
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-img">
                                    <img src="images/features-img/questions_small.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="features-content mt-32">
                                    <div className="features-icon">
                                        <i className="pe-7s-medal text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Verified Data</h4>
                                    <p className="text-muted f-14">Fundify gathers data from multiple sources and combines this to give greater insight when you need results quickly. We use our charity relationships, network & community to verify our data. Making sure we have the most up-to-date and accurate information available.</p>
                                    <p className="text-muted f-14">Personalised to help you!</p>
                                </div>
                                <div className="features-content mt-32">
                                    <div className="features-icon">
                                        <i className="pe-7s-medal text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Community</h4>
                                    <p className="text-muted f-14">As well as great results you can also get additional help, support and advice from within our online community.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="section bg-features bg-light">
                    <div className="container">
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="features-content">
                                    <div className="features-icon">
                                        <i className="pe-7s-display1 text-primary"></i>
                                    </div>
                                    <h4 className="font-weight-normal text-dark mb-3 mt-4">Personalised to help you</h4>
                                    <p className="text-muted f-14">Coming soon.</p>
                                    <p className="text-muted f-14">The Fundify platform will allow for advanced levels of personalisation for our Community+ users making it quicker and easier to identify the funds you need. Our robots will even suggest funds that are a good fit for your organisation.</p>
                                    <p className="text-muted f-14">By using our “smart system” that learns from each interaction, not only will we get you to the right funders more quickly, but we will also make you more productive, by saving research time.</p>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mt-40">
                                    {/*<img src="images/features-img/img-3.png" alt="" className="img-fluid mx-auto d-block" />*/}
                                    <img
                                        src="/images/fundify_product_demo.png"
                                        alt="Fundify Product Demo"
                                        className="img-fluid mx-auto d-block"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default FeatureSection;