import React from 'react';

import ScrollspyNav from './scrollSpy';

export const Logo = ({color = '#ffffff'}) => (
    // <div style={{ width: '200px'}}>
        <svg
            viewBox="0 0 232 79"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Fundify-Logo"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="01---Logo"
                    transform="translate(-431.000000, -708.000000)"
                >
                    <g
                        id="Group-3-Copy-2"
                        transform="translate(412.000000, 689.000000)"
                    >
                        <g
                            id="fundify-logo-copy-20"
                            transform="translate(19.000000, 19.000000)"
                        >
                            <path
                                d="M62.289139,61.9966556 C59.2672504,61.9966556 56.9647002,61.0913615 55.3814884,59.2807734 C53.7982767,57.4701852 53.0044472,54.8334063 53,51.3704368 L53,32 L63.7633938,32 L63.7633938,48.9577841 C63.7633938,50.2761735 64.0535752,51.2649656 64.6406087,51.9801919 C65.2275898,52.6894466 66.1191928,53.0829293 67.0454451,53.0414954 C67.925079,53.0320859 68.7930285,52.8412397 69.5937044,52.4811799 C70.4631367,52.1098335 71.332569,51.2891361 72.2020013,50.0190876 L72.2020013,32 L82.9620596,32 L82.9620596,49.6828983 C82.9620596,50.6914662 83.1132652,51.3913113 83.4156765,51.7824335 C83.7180877,52.1735557 84.2461955,52.4064711 85,52.4811799 L85,61.4363401 C83.5118813,61.7664247 81.9950412,61.9540633 80.4705024,61.9966556 C76.8037662,61.9966556 74.6913349,60.6343199 74.1332083,57.9096484 L73.9063998,56.8483449 C72.6327796,58.5829885 70.9120801,59.948732 68.9232854,60.8035132 C66.8149304,61.6357355 64.5590235,62.0414573 62.289139,61.9966556 Z"
                                id="Path"
                                fill={color}
                            />
                            <path
                                d="M118.986644,62 L108.215359,62 L108.215359,45.4253098 C108.215359,43.9698853 107.881469,42.915692 107.213689,42.2627296 C106.618615,41.6345272 105.787528,41.2765961 104.916528,41.2733928 C104.008347,41.2733928 103.044519,41.6647305 102.025042,42.4474058 C101.003481,43.2316125 100.21932,44.2775962 99.7579299,45.4714788 L99.7579299,61.9901066 L89,61.9901066 L89,32.5474417 L98.6828047,32.5474417 L98.6828047,36.913715 C99.7734721,35.290171 101.323641,34.019429 103.140234,33.2597642 C105.050083,32.4177286 107.308292,31.9978101 109.914858,32.0000086 C111.956038,32.0000086 113.572065,32.3638647 114.762938,33.0915769 C115.888335,33.755176 116.824669,34.6901938 117.48414,35.8089555 C118.106993,36.8761305 118.524698,38.0478535 118.716194,39.2650389 C118.896053,40.3765654 118.990923,41.4998896 119,42.6254865 L118.986644,62 Z"
                                id="Path"
                                fill={color}
                            />
                            <path
                                d="M136.111173,62.9991784 C134.164589,63.0203231 132.235279,62.6331322 130.448337,61.8627103 C128.747945,61.124828 127.22597,60.0317453 125.985085,58.657202 C124.696152,57.2167318 123.700352,55.539955 123.053143,53.7202513 C122.334778,51.7070549 121.978589,49.5830933 122.000995,47.4462793 C121.983079,45.365791 122.310289,43.2966755 122.969373,41.3227222 C123.556111,39.5318598 124.471961,37.8656188 125.67011,36.4091693 C126.798819,35.0503774 128.205032,33.9473166 129.794933,33.173578 C131.445549,32.3854799 133.256031,31.9874438 135.085831,32.0103696 C136.942414,31.9853149 138.775181,32.429157 140.413588,33.300595 C141.989824,34.1356816 143.28547,35.4141951 144.139667,36.9774033 L144.139667,21 L154.949316,21 L154.949316,50.5114004 C154.949316,51.5342216 155.101219,52.243957 155.405024,52.6406066 C155.709946,53.0383704 156.239371,53.2756917 157,53.3492279 L157,62.4309444 C155.503927,62.7659067 153.978964,62.9561984 152.446276,62.9991784 C148.767108,62.9991784 146.644941,61.6175898 146.079774,58.8544127 L145.855271,57.7781106 C144.924341,59.5054395 143.470146,60.8952275 141.700291,61.7490635 C139.956144,62.5824628 138.045059,63.009914 136.111173,62.9991784 Z M139.073272,53.9174619 C141.043536,53.9174619 142.750206,52.8957549 144.193279,50.8523408 L144.193279,45.504256 C143.709079,44.2477911 142.899762,43.1417529 141.847726,42.2987477 C140.941779,41.5279822 139.794721,41.0965289 138.604161,41.0787158 C137.843013,41.0702122 137.091118,41.2456817 136.412744,41.5901264 C135.746195,41.930967 135.163698,42.4149974 134.707192,43.0073689 C134.216712,43.6413871 133.832011,44.3502061 133.567923,45.1064922 C133.279564,45.9268945 133.135593,46.7908598 133.142373,47.6602027 C133.142373,49.554316 133.682967,51.0684925 134.764155,52.2027322 C135.845344,53.3369718 137.281716,53.9085484 139.073272,53.9174619 Z"
                                id="Shape"
                                fill={color}
                                fillRule="nonzero"
                            />
                            <path
                                d="M161,29.9872529 L161,21 L172,21 L172,29.9872529 L161,29.9872529 Z M161,62 L161,32.4581686 L172,32.4581686 L172,62 L161,62 Z"
                                id="Shape"
                                fill={color}
                                fillRule="nonzero"
                            />
                            <path
                                d="M179.690198,62 L179.690198,43.7264109 L176,43.7264109 L176,35.610758 L179.690198,35.610758 L179.690198,32.3751929 C179.668956,30.6490504 179.938852,28.9316126 180.488623,27.2945535 C180.965651,25.8570386 181.720069,24.5262788 182.709452,23.3771132 C183.658637,22.3014285 184.83528,21.4486961 186.154755,20.8802463 C187.587083,20.2781855 189.129225,19.9788458 190.683635,20.001162 C193.188497,20.001162 195.960618,20.5682772 199,21.7025077 L197.178384,29.878326 C196.499032,29.6414252 195.804068,29.4515635 195.098454,29.3100966 C194.452351,29.1695599 193.793818,29.093421 193.132585,29.0828049 C191.388127,29.0828049 190.51478,30.0476807 190.512544,31.9774323 L190.512544,35.610758 L197.064323,35.610758 L197.064323,43.7264109 L190.512544,43.7264109 L190.512544,62 L179.690198,62 Z"
                                id="Path"
                                fill={color}
                            />
                            <path
                                d="M202.661271,68.7621479 C204.433181,69.4019945 205.937032,69.7230287 207.172825,69.7252504 C209.524012,69.7252504 210.816597,68.4044558 211.05058,65.7628667 L200,36 L211.282292,36 L217.075072,56.6617147 L221.760409,36 L232,36 L221.702481,69.3253462 C221.264325,70.7504698 220.600927,72.0997931 219.736343,73.3243878 C218.899973,74.4980158 217.873302,75.5302361 216.696837,76.380322 C215.511303,77.2328717 214.192613,77.8923371 212.791822,78.3331873 C211.352091,78.7819218 209.849255,79.0068287 208.338196,78.9996943 C206.393971,79.0119728 204.465863,78.654305 202.661271,77.9466133 L202.661271,68.7621479 Z"
                                id="Path"
                                fill={color}
                            />
                            <polygon
                                id="Path"
                                fill={color}
                                points="49 32 49 22 44.4324387 22 35 32"
                            />
                            <polygon
                                id="Path"
                                fill={color}
                                points="22 44.9119586 22 62 33.2588411 62 33.2588411 47.2288136 47 47.2288136 47 38.3865348 33.2588411 38.3865348 33.2588411 34"
                            />
                            <polygon
                                id="Path"
                                fill={color}
                                points="22 22 22 41 41 22"
                            />
                            <path
                                d="M5.26853982,20.1075155 C5.27251927,12.4121156 11.2564857,5.9736685 19.1055529,5.21958508 C26.95462,4.46550165 34.1106888,9.64154611 35.6528745,17.1884013 L41,17.1884013 C39.4599726,6.95145809 30.209586,-0.47430988 19.6175956,0.0236295895 C9.02560516,0.521569059 0.549758269,8.780664 0.0255510628,19.1146488 C-0.498656143,29.4486336 7.09990339,38.4839867 17.589733,40 L17.589733,34.7827891 C10.4248663,33.3782016 5.26963279,27.238012 5.26853982,20.1075155 Z"
                                id="Path"
                                fill={color}
                            />
                            <path
                                d="M20.7269213,11 C15.9475529,11.0176825 11.8841566,14.3062555 11.1248737,18.7710938 C10.3655907,23.2359321 13.1336114,27.5646026 17.6658714,29 L17.6658714,23.5083636 C16.3673197,22.4338543 15.810696,20.7636948 16.2229088,19.1787283 C16.6351215,17.5937617 17.9473133,16.3587464 19.6245671,15.977138 C21.3018209,15.5955297 23.0639625,16.1310737 24.1926523,17.3654545 L30,17.3654545 C28.6893477,13.569241 24.9476409,11.0007667 20.7269213,11 Z"
                                id="Path"
                                fill={color}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    // </div>
)

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {Tab: ''};
    }

    /**
     * Sets active tab
     */
    setActiveTab = (tab, e) => {
        this.setState({Tab: tab});
    }

    render() {

        return (
            <React.Fragment>
                <nav id="main_navbar" className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
                    <div className="container">
                        <div className="nav-logo-container">
                            <Logo />
                        </div>

                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <i className="mdi mdi-menu"></i>
                        </button>


                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ScrollspyNav
                                scrollTargetIds={["home", "about", "robot", "features", "pricing"]}
                                activeNavClass="active"
                                scrollDuration="800"
                                headerBackground="true"
                                className="ml-auto">
                                <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                                    <li className="nav-item active"><a href="#home" className="nav-link">Home</a></li>
                                    <li className="nav-item"><a href="#about" className="nav-link">About</a></li>
                                    <li className="nav-item"><a href="#robot" className="nav-link">Robot</a></li>
                                    <li className="nav-item"><a href="#features" className="nav-link">Features</a></li>
                                    <li className="nav-item"><a href="#partners" className="nav-link">Partners</a></li>
                                    <li className="nav-item"><a href="#pricing" className="nav-link">Pricing</a></li>
                                    {/*<li className="nav-item"><a href="#contact" className="nav-link">Contact us</a></li>*/}
                                </ul>
                            </ScrollspyNav>
                        </div>
                    </div>
                </nav>
            </React.Fragment>
        );
    }

}

export default Navbar;