import React from 'react';
import {Logo} from "./components/Navbar";
import Footer from "./components/Footer";
import FooterAlt from "./components/FooterAlt";
import {Link} from "react-router-dom";

const Privacy = () => {
    return (
        <React.Fragment>
            <nav id="main_navbar" className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark">
                <div className="container">
                    <div className="nav-logo-container">
                        <Logo />
                    </div>

                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </button>


                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                            <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
                            <li className="nav-item"><Link to="/privacy" className="nav-link">Privacy</Link></li>
                            <li className="nav-item active"><Link to="/terms" className="nav-link">Terms</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <section className="section bg-about bg-light-about bg-light" id="terms">
                <div className="container" style={{marginTop: '25px'}}>
                    <h1 className="text-dark mb-3 font-weight-light">Terms</h1>
                    <div className="component--terms-and-conditions"><h1>Terms and conditions of use</h1>
                        <h2>Introduction</h2><p>These terms and conditions apply between you, the User of this Website
                            and Platform (hereinafter referred to solely as Platform) (including any sub-domains, unless
                            expressly excluded by their own terms and conditions), and&nbsp;Fundify Limited,&nbsp;the
                            owner and operator of this Platform. Please read these terms and conditions carefully, as
                            they affect your legal rights. Your agreement to comply with and be bound by these terms and
                            conditions is deemed to occur upon your first use of the Platform. If you do not agree to be
                            bound by these terms and conditions, you should stop using the Platform immediately.</p>
                        <p>In these terms and conditions,&nbsp;User&nbsp;or&nbsp;Users&nbsp;means any third party that
                            accesses the Platform and is not either (i) employed by&nbsp;Fundify Limited&nbsp;&nbsp;and
                            acting in the course of their employment or (ii) engaged as a consultant or otherwise
                            providing services to&nbsp;Fundify Limited&nbsp;&nbsp;and accessing the Platform in
                            connection with the provision of such services.</p><p>You must be at least 18 years of age
                            to use this Platform. By using the Platform and agreeing to these terms and conditions, you
                            represent and warrant that you are at least 18 years of age.</p><h2>Intellectual property
                            and acceptable use</h2>
                        <ol>
                            <li><p>All Content included on the Platform, unless uploaded by Users, is the property
                                of&nbsp;Fundify Limited,&nbsp;our affiliates or other relevant third parties. In these
                                terms and conditions, Content means any text, graphics, images, audio, video, software,
                                data compilations, page layout, underlying code and software and any other form of
                                information capable of being stored in a computer that appears on or forms part of this
                                Platform, including any such content uploaded by Users. By continuing to use the
                                Platform you acknowledge that such Content is protected by copyright, trademarks,
                                database rights and other intellectual property rights. Nothing on this site shall be
                                construed as granting, by implication, estoppel, or otherwise, any license or right to
                                use any trademark, logo or service mark displayed on the site without the owner's
                                express prior written permission</p></li>
                            <li><p>You may, for your own personal, non-commercial use only, do the following:</p>
                                <ol>
                                    <li><p>retrieve, display and view the Content on a computer screen</p></li>
                                    <li><p>download and store the Content in electronic form on a disk (but not on any
                                        server or other storage device connected to a network)</p></li>
                                    <li><p>print one copy of the Content</p></li>
                                </ol>
                            </li>
                            <li><p>You must not otherwise reproduce, modify, copy, distribute or use for commercial
                                purposes any Content without the written permission of&nbsp;Fundify Limited.</p></li>
                            <li><p>You acknowledge that you are responsible for any Content you may submit via the
                                Platform, including the legality, reliability, appropriateness, originality and
                                copyright of any such Content. You may not upload to, distribute or otherwise publish
                                through the Platform any Content that (i) is confidential, proprietary, false,
                                fraudulent, libellous, defamatory, obscene, threatening, invasive of privacy or
                                publicity rights, infringing on intellectual property rights, abusive, illegal or
                                otherwise objectionable; (ii) may constitute or encourage a criminal offence, violate
                                the rights of any party or otherwise give rise to liability or violate any law; or (iii)
                                may contain software viruses, political campaigning, chain letters, mass mailings, or
                                any form of "spam." You may not use a false email address or other identifying
                                information, impersonate any person or entity or otherwise mislead as to the origin of
                                any content. You may not upload commercial content onto the Platform, without the
                                express permission of Fundify Limited, which will only be permitted through advertising
                                channels or following completion of our IP ownership and transfer document. </p></li>
                            <li><p>You represent and warrant that you own or otherwise control all the rights to the
                                Content you post; that the Content is accurate; that use of the Content you supply does
                                not violate any provision of these terms and conditions and will not cause injury to any
                                person; and that you will indemnify&nbsp;Fundify Limited&nbsp;&nbsp;for all claims
                                resulting from Content you supply as your own or which you own the IP to.</p></li>
                            <h2>Prohibited use</h2>
                            <li><p>You may not use the Platform for any of the following purposes:</p>
                                <ol>
                                    <li><p>in any way which causes, or may cause, damage to the Platform or interferes
                                        with any other person's use or enjoyment of the Platform;</p></li>
                                    <li><p>in any way which is harmful, unlawful, illegal, abusive, harassing,
                                        threatening or otherwise objectionable or in breach of any applicable law,
                                        regulation, governmental order;</p></li>
                                    <li><p>making, transmitting or storing electronic copies of Content on the Platform
                                        protected by copyright or otherwise without the permission of the owner and
                                        Fundify Limited.</p></li>
                                </ol>
                            </li>
                            <h2>Registration</h2>
                            <li><p>You must ensure that the details provided by you on registration or at any time are
                                correct and complete.</p></li>
                            <li><p>You must inform us immediately of any changes to the information that you provide
                                when registering by updating your personal details to ensure we can communicate with you
                                effectively.</p></li>
                            <li><p>We may suspend or cancel your registration with immediate effect for any reasonable
                                purposes or if you breach these terms and conditions. This may include cancellation of
                                subscription without recompense when appropriate, determined at the sole discretion of
                                Fundify Limited.</p></li>
                            <li><p>You may cancel your registration at any time by informing us in writing to the
                                address at the end of these terms and conditions. If you do so, you must immediately
                                stop using the Platform. Cancellation or suspension of your registration does not affect
                                any statutory rights.</p></li>
                            <h3>Password and security</h3>
                            <li><p>When you register on this Platform, you will be asked to create a password, which you
                                should keep confidential and not disclose or share with anyone.</p></li>
                            <li><p>When registering through another account such as Facebook, Instagram, linked in,
                                Twitter. You agreed to you details being shared between these providers and Fundify
                                Limited to allow access to the platform.</p></li>
                            <li><p>If we have reason to believe that there is or is likely to be any misuse of the
                                Platform or breach of security, we may require you to change your password or suspend
                                your account.</p></li>
                            <h2>Links to other Platforms</h2>
                            <li><p>This Platform does contain links to other sites. Unless expressly stated, these sites
                                are not under the control of&nbsp;Fundify Limited&nbsp;or that of our partners or
                                affiliates.</p></li>
                            <li><p>We assume no responsibility for the content of such websites and disclaim liability
                                for any and all forms of loss or damage arising out of the use of them</p></li>
                            <li><p>The inclusion of a link to another site on this Platform does not imply any
                                endorsement of the sites themselves or of those in control of them.</p></li>
                            <h2>Privacy Policy and Cookies Policy</h2>
                            <li><p>Use of the Platform is also governed by our Privacy Policy and Cookies Policy, which
                                are incorporated into these terms and conditions by this reference. To view the Privacy
                                Policy and Cookies Policy, please click on the
                                following:&nbsp;www.fundify.org.uk/privacy&nbsp;and&nbsp;www.fundify.org.uk/cookies.</p>
                            </li>
                            <h2>Availability of the Platform and disclaimers</h2>
                            <li><p>Any online facilities, tools, services or information that&nbsp;Fundify
                                Limited&nbsp;&nbsp;makes available through the Platform (the&nbsp;Service) is provided
                                "as is" and on an "as available" basis. We give no warranty that the Service will be
                                free of defects and/or faults. To the maximum extent permitted by the law, we provide no
                                warranties (express or implied) of fitness for a particular purpose, accuracy of
                                information, compatibility and satisfactory quality.&nbsp;&nbsp;Fundify Limited&nbsp;is
                                under no obligation to update information on the Platform.</p></li>
                            <li><p>Whilst&nbsp;Fundify Limited&nbsp;&nbsp;uses reasonable endeavours to ensure that the
                                Platform is secure and free of errors, viruses and other malware, we give no warranty or
                                guaranty in that regard and all Users take responsibility for their own security, that
                                of their personal details and their computers.</p></li>
                            <li><p>Fundify Limited&nbsp;accepts no liability for any disruption or non-availability of
                                the Platform.</p></li>
                            <li><p>Fundify Limited&nbsp;reserves the right to alter, suspend or discontinue any part (or
                                the whole of) the Platform including, but not limited to, any products and/or services
                                available. These terms and conditions shall continue to apply to any modified version of
                                the Platform unless it is expressly stated otherwise.</p></li>
                            <h2>Limitation of liability</h2>
                            <li><p>Nothing in these terms and conditions will: (a) limit or exclude our or your
                                liability for death or personal injury resulting from our or your negligence, as
                                applicable; (b) limit or exclude our or your liability for fraud or fraudulent
                                misrepresentation; or (c) limit or exclude any of our or your liabilities in any way
                                that is not permitted under applicable law.</p></li>
                            <li><p>To the extent that the Platform and Content are provided free of charge, we will not
                                be liable to you for any loss or damage of any kind.</p></li>
                            <li><p>Where a subscription is purchased our maximum liability will be to the value of the
                                subscription.</p></li>
                            <li><p>We will not be liable to you in respect of any losses arising out of events beyond
                                our reasonable control.</p></li>
                            <li><p>To the maximum extent permitted by law,&nbsp;Fundify Limited&nbsp;accepts no
                                liability for any of the following:</p>
                                <ol>
                                    <li><p>any business losses, such as loss of profits, income, revenue, anticipated
                                        savings, business, contracts, goodwill or commercial opportunities;</p></li>
                                    <li><p>loss or corruption of any data, database or software;</p></li>
                                    <li><p>any special, indirect or consequential loss or damage.</p></li>
                                </ol>
                            </li>
                            <h2>General</h2>
                            <li><p>You may not transfer any of your rights under these terms and conditions to any other
                                person. We may transfer our rights under these terms and conditions where we reasonably
                                believe your rights will not be affected.</p></li>
                            <li><p>These terms and conditions may be varied by us from time to time. Such revised terms
                                will apply to the Platform from the date of publication. Users should check the terms
                                and conditions regularly to ensure familiarity with the then current version.</p></li>
                            <li><p>These terms and conditions&nbsp;together with the Privacy Policy and Cookies
                                Policy&nbsp;IP transfer agreement contain the whole agreement between the parties
                                relating to its subject matter and supersede all prior discussions, arrangements or
                                agreements that might have taken place in relation to the terms and conditions.</p></li>
                            <li><p>The&nbsp;Contracts (Rights of Third Parties) Act 1999&nbsp;shall not apply to these
                                terms and conditions and no third party will have any right to enforce or rely on any
                                provision of these terms and conditions.</p></li>
                            <li><p>If any court or competent authority finds that any provision of these terms and
                                conditions (or part of any provision) is invalid, illegal or unenforceable, that
                                provision or part-provision will, to the extent required, be deemed to be deleted, and
                                the validity and enforceability of the other provisions of these terms and conditions
                                will not be affected.</p></li>
                            <li><p>Unless otherwise agreed, no delay, act or omission by a party in exercising any right
                                or remedy will be deemed a waiver of that, or any other, right or remedy.</p></li>
                            <li><p>This Agreement shall be governed by and interpreted according to the law
                                of&nbsp;England and Wales&nbsp;and all disputes arising under the Agreement (including
                                non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of
                                the&nbsp;English and Welsh&nbsp;courts.</p></li>
                            <h2>Fundify Limited&nbsp;details</h2>
                            <li><p>Fundify Limited&nbsp;is a company incorporated in&nbsp;England and Wales&nbsp;with
                                registered number&nbsp;10086926 &nbsp;whose registered address is The
                                Greenhouse,&nbsp;Greencroft Industrial Park,&nbsp;Stanley,&nbsp;DH9 7XN&nbsp;and
                                it&nbsp;operates the Platform&nbsp;Www.fundify.org.uk and www.wearefundify.org.uk and
                                wearefundify.co.uk and wearefundify.com.&nbsp;&nbsp;The registered VAT number
                                is&nbsp;333242534. You can contact&nbsp;Fundify Limited&nbsp;by email
                                on&nbsp;Terms@fundify.org.uk.</p></li>
                        </ol>
                    </div>
                </div>
            </section>
            <Footer />
            <FooterAlt />
        </React.Fragment>
    )
}

export default Privacy;