import React from 'react';
import {Col, Row} from 'reactstrap';

class RobotSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            AboutList: [{
                "icon": "mdi mdi-lightbulb",
                "title": "Creative Design",
                "content": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni.",
            }, {
                "icon": "mdi mdi-projector-screen",
                "title": "Strategy Solutions",
                "content": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni.",
            }, {
                "icon": "mdi mdi-nature",
                "title": "Dynamic Growth",
                "content": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur at aut odit aut fugit sed quia consequuntur magni.",
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section bg-robot" id="robot">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Robot</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="align-items-center mt-5">
                            <Col md="6">
                                <div className="robot-desc">
                                    <h3 className="text-dark mb-3 font-weight-light">Feed our robot</h3>
                                    <p className="text-muted f-15">We are; with help, building the UK's first AI-Powered research bot, and whilst it's munching through the 40M data points in the Fundify system we still have to feed it new search information as it's constantly hungry.</p>
                                    <p className="text-muted f-15">Please help us to feed the robot by <a href="https://docs.google.com/forms/d/1YOJilR5asC46mOgIoDNTfhi1hUoxL9kpeoQMbRg8EfY" target="_blank" rel="noopener noreferrer">filling in the Google form</a> with search terms relevant for your organisation - you can do as many as you like and these can be keyword or long-form text. When the robot is ready we will send as a thank you personalised grant insights based on the searches entered and will also offer a 25% off voucher to the named organisational contact.</p>
                                </div>
                            </Col>

                            <Col md="6">
                            <div>
        <img src="images\robot.jpg" className="img-fluid mx-auto d-block" alt="robot"/>
      </div>
        </Col>
                            </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default RobotSection;