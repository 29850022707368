import React from 'react';
import {Col, Row} from 'reactstrap';
import ContactSection from './ContactSection';


class Home4 extends React.Component {

    
  constructor() {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

    render() {

        return (
            <React.Fragment>
                <section className="section home-4-bg" id="home">
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <Row className="align-items-center">
                                    <Col className="col-lg-6 position-static">
                                        <div className="mt-40 home-4-content">
                                            <h1 className="text-white font-weight-normal home-2-title mb-0">Grant Funding Made Simple.</h1>
                                            <p className="text-white-70 mt-4 f-15 mb-0">Join us as we bring modern technology to the world of grant funding. Save time, increase your ROI and success rates and impact. Built for fundraising, we welcome charities, funders, all groups, fundraisers, consultants and researchers. Together Wearefundify.</p>
                                        </div>
                                    </Col>
                                    <Col lg="6" className="position-static">
                                        <div className="mt-40 home-4-img position-relative pl-5">
                                            <ContactSection />
                                            {/*<img src="images/illustration-data-points.svg" alt="" className="img-fluid mx-auto d-block" />*/}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
          
        );
    }
}
export default Home4;