import React from 'react';
import {Logo} from "./components/Navbar";
import Footer from "./components/Footer";
import FooterAlt from "./components/FooterAlt";
import {Link} from "react-router-dom";

const Privacy = () => {
    return (
        <React.Fragment>
            <nav id="main_navbar" className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark">
                <div className="container">
                    <div className="nav-logo-container">
                        <Logo />
                    </div>

                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </button>


                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                            <li className="nav-item active"><Link to="/" className="nav-link">Home</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <section className="section bg-about bg-light-about bg-light" id="privacy">
                <div className="container" style={{marginTop: '25px'}}>
                    <h1 className="text-dark mb-3 font-weight-light">Privacy</h1>
                    <p>
                        This privacy policy applies between you, the User of this Website and Platform, (hereinafter
                        solely referred to as Platform) and Fundify Limited, the owner and provider of this Platform.
                        Fundify Limited takes the privacy of your information very seriously. This privacy policy
                        applies to our use of any and all Data collected by us or provided by you in relation to your
                        use of the Platform.
                    </p>
                    <p>
                        This privacy policy should be read alongside, and in addition to, our Terms and Conditions,
                        which can be found <Link to="/terms">HERE</Link>
                    </p>
                    <p className="text-dark mb-3 font-weight-bold">Please read this privacy policy carefully.</p>
                    <div className="privacy-policy">
                        <ul>
                            <li>In this privacy policy, the following definitions are used:</li>
                        </ul>
                        <table>
                            <tbody>
                            <tr>
                                <td><p><strong>Data</strong></p></td>
                                <td><p>collectively all information that you submit to&nbsp;Fundify Limited&nbsp;via the
                                    Platform. This definition incorporates, where applicable, the definitions provided
                                    in the Data Protection Laws;</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>Cookies</strong></p></td>
                                <td><p>a small text file placed on your computer by this Website when you visit certain
                                    parts of the Website and/or when you use certain features of the Website. Details of
                                    the cookies used by this Website are set out in the clause below
                                    (<strong>Cookies</strong>);</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>Data Protection Laws</strong></p></td>
                                <td><p>any applicable law relating to the processing of personal Data, including but not
                                    limited to the Directive 96/46/EC (Data Protection Directive) or the GDPR, and any
                                    national implementing laws, regulations and secondary legislation, for as long as
                                    the GDPR is effective in the UK;</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>GDPR</strong></p></td>
                                <td><p>the General Data Protection Regulation (EU) 2016/679;</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>Fundify Limited, <br /> we&nbsp;</strong>or&nbsp;<strong>us</strong></p>
                                </td>
                                <td><p>&nbsp;Fundify Limited, a company incorporated in&nbsp;England and Wales&nbsp;with
                                    registered number&nbsp;10086926&nbsp;whose registered office is at, The Greenhouse,
                                    Greencroft Industrial Park, Stanley, DH9 7XN;&nbsp;</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>UK and EU Cookie Law</strong></p></td>
                                <td><p>the Privacy and Electronic Communications (EC Directive) Regulations 2003 as
                                    amended by the Privacy and Electronic Communications (EC Directive) (Amendment)
                                    Regulations 2011;</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>User</strong>&nbsp;or&nbsp;<strong>you</strong></p></td>
                                <td><p>any third party that accesses the Platform and is not either (i) employed
                                    by&nbsp;Fundify Limited&nbsp;and acting in the course of their employment or (ii)
                                    engaged as a consultant or otherwise providing services to&nbsp;Fundify
                                    Limited&nbsp;and accessing the Platform in connection with the provision of such
                                    services; and</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>Website</strong></p></td>
                                <td><p>the Platform that you are currently using,&nbsp;www.fundify.org.uk and
                                    wearefundify.org.uk and wearefundify.co.uk and wearefundify.com, and any sub-domains
                                    of this site unless expressly excluded by their own terms and conditions.</p></td>
                            </tr>
                            </tbody>
                        </table>
                        <br /><br />
                        <ul>
                            <li>In this privacy policy, unless the context requires a different interpretation:
                                <ul>
                                    <li>the singular includes the plural and vice versa;</li>
                                    <li>references to sub-clauses, clauses, schedules or appendices are to
                                        sub-clauses, clauses, schedules or appendices of this privacy policy;
                                    </li>
                                    <li>a reference to a person includes firms, companies, government entities,
                                        trusts and partnerships;
                                    </li>
                                    <li>"including" is understood to mean "including without limitation”.</li>
                                    <li>reference to any statutory provision includes any modification or amendment
                                        of it;
                                    </li>
                                    <li>the headings and sub-headings do not form part of this privacy policy.</li>
                                </ul>
                            </li>
                        </ul>
                        <h2>Scope of this privacy policy</h2>
                        <ul>
                            <li>This privacy policy applies only to the actions of&nbsp;Fundify Limited&nbsp;and
                                Users with respect to this Platform. It does not extend to any sites that can be
                                accessed from or through this Platform including, but not limited to, any links we
                                may provide to funders external sites or social media websites.
                            </li>
                            <li>For purposes of the applicable Data Protection Laws,&nbsp;Fundify Limited&nbsp;is
                                the "data controller". This means that&nbsp;Fundify Limited&nbsp;determines the
                                purposes for which, and the manner in which, your Data is processed.
                            </li>
                        </ul>
                        <h2>Data collected</h2>
                        <ul>
                            <li>We may collect the following Data, which includes personal Data, from you:
                                <ul>
                                    <li>name;</li>
                                    <li>date of birth;</li>
                                    <li>gender;</li>
                                    <li>job title;</li>
                                    <li>profession;</li>
                                    <li>contact Information such as email addresses and telephone numbers;</li>
                                    <li>demographic information such as postcode, preferences and interests;</li>
                                    <li>financial information such as credit / debit card numbers;</li>
                                    <li>IP address (automatically collected);</li>
                                    <li>web browser type and version (automatically collected);</li>
                                    <li>operating system (automatically collected);</li>
                                    <li>a list of URLs starting with a referring site, your activity on this
                                        Website, and the site you exit to (automatically collected);
                                    </li>
                                    <li>Cookies and data for the purposes of analytics to ensure use and
                                        functionality of the platform;
                                    </li>
                                    <li>Information uploaded by users to update the platform;</li>
                                    <li>Information, bids, templates and completed applications for library
                                        inclusion and IP transfer;
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>in each case, in accordance with this privacy policy.</p><h2>How we collect Data</h2>
                        <ul>
                            <li>We collect Data in the following ways:
                                <ul>
                                    <li>data is given to us by you&nbsp;;&nbsp;</li>
                                    <li>data is received from other sources; and</li>
                                    <li>data is collected automatically.</li>
                                </ul>
                            </li>
                        </ul>
                        <h2>Data that is given to us by you</h2>
                        <ul>
                            <li>Fundify Limited&nbsp;will collect your Data in a number of ways, for example:
                                <ul>
                                    <li>when you contact us through the Website, by telephone, post, e-mail or
                                        through any other means;
                                    </li>
                                    <li>when you register with us and set up an account to receive our
                                        products/services;
                                    </li>
                                    <li>when you complete surveys that we use for research purposes (although you
                                        are not obliged to respond to them);
                                    </li>
                                    <li>when you enter a competition or promotion through a social media channel;
                                    </li>
                                    <li>when you make payments to us, through this Website or otherwise;</li>
                                    <li>when you elect to receive marketing communications from us;</li>
                                    <li>when you use our services;</li>
                                    <li>from trusts, or other subscribers or advertisers on the platform;</li>
                                </ul>
                            </li>
                        </ul>
                        <p>in each case, in accordance with this privacy policy.</p><h2>Data that is received from
                        third parties</h2>
                        <ul>
                            <li>Fundify Limited&nbsp;will receive Data about you from the following third parties:
                                <ul>
                                    <li>online communities of interest.</li>
                                </ul>
                            </li>
                        </ul>
                        <h2>Data that is received from publicly available third parties sources</h2>
                        <ul>
                            <li>We will receive Data about you from the following publicly available third party
                                sources:
                                <ul>
                                    <li>google;&nbsp;</li>
                                    <li>linked in;&nbsp;</li>
                                    <li>twitter;&nbsp;</li>
                                    <li>instagram;&nbsp;</li>
                                    <li>go cardless;&nbsp;</li>
                                    <li>Facebook;&nbsp;</li>
                                    <li>quantcast.com.</li>
                                </ul>
                            </li>
                        </ul>
                        <h2>Data that is collected automatically</h2>
                        <ul>
                            <li>To the extent that you access the Platform, we will collect your Data automatically,
                                for example:
                                <ul>
                                    <li>we automatically collect some information about your visit to the Platform.
                                        This information helps us to make improvements to Platform content and
                                        navigation, and includes your IP address, the date, times and frequency with
                                        which you access the Platform and the way you use and interact with its
                                        content.
                                    </li>
                                    <li>we will collect your Data automatically via cookies, in line with the cookie
                                        settings on your browser. For more information about cookies, and how we use
                                        them on the Platform, see the section below, headed "Cookies".
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2>Our use of Data</h2>
                        <ul>
                            <li>Any or all of the above Data may be required by us from time to time in order to
                                provide you with the best possible service and experience when using our Platform.
                                Specifically, Data may be used by us for the following reasons:
                                <ul>
                                    <li>internal record keeping;</li>
                                    <li>improvement of our products /services;</li>
                                    <li>transmission by email of marketing materials that may be of interest to
                                        you;
                                    </li>
                                    <li>contact for market research purposes which may be done using email,
                                        telephone, fax or mail. Such information may be used to customise or update
                                        the Platform;
                                    </li>
                                    <li>Data analytics and reporting;</li>
                                </ul>
                            </li>
                        </ul>
                        <p>in each case, in accordance with this privacy policy.</p>
                        <ul>
                            <li>We may use your Data for the above purposes if we deem it necessary to do so for our
                                legitimate interests. If you are not satisfied with this, you have the right to
                                object in certain circumstances (see the section headed "Your rights" below).
                            </li>
                        </ul>
                        <ul>
                            <li>For the delivery of direct marketing to you via e-mail, we'll need your consent,
                                whether via an opt-in or soft-opt-in:
                                <ul>
                                    <li>soft opt-in consent is a specific type of consent which applies when you
                                        have previously engaged with us (for example, you contact us to ask us for
                                        more details about a particular product/service, and we are marketing
                                        similar products/services). Under "soft opt-in" consent, we will take your
                                        consent as given unless you opt-out.
                                    </li>
                                    <li>for other types of e-marketing, we are required to obtain your explicit
                                        consent; that is, you need to take positive and affirmative action when
                                        consenting by, for example, checking a tick box that we'll provide.
                                    </li>
                                    <li>if you are not satisfied about our approach to marketing, you have the right
                                        to withdraw consent at any time. To find out how to withdraw your consent,
                                        see the section headed "Your rights" below.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul>
                            <li>When you register with us and set up an account to receive our services, the legal
                                basis for this processing is the performance of a contract between you and us and/or
                                taking steps, at your request, to enter into such a contract.
                            </li>
                        </ul>
                        <h2>Who we share Data with</h2>
                        <ul>
                            <li>We may share your Data with the following groups of people for the following
                                reasons:
                                <ul>
                                    <li>any of our group companies or affiliates -&nbsp;to ensure the proper
                                        administration of the platform and business;
                                    </li>
                                    <li>our employees, agents and/or professional advisors -&nbsp;information will
                                        be shared with employees in order for them to be able to undertake
                                        administrative duties related to user accounts;
                                    </li>
                                    <li>third party service providers who provide services to us which require the
                                        processing of personal data -&nbsp;to help third party service providers in
                                        receipt of any shared data to perform functions on our behalf to help ensure
                                        the platform runs smoothly;
                                    </li>
                                    <li>third party payment providers who process payments made over the Platform
                                        -&nbsp;to enable third party payment providers to process user payments and
                                        refunds;
                                    </li>
                                    <li>relevant authorities -&nbsp;to facilitate the detection of crime or the
                                        collection of taxes or duties;
                                    </li>
                                    <li>UK Universities&nbsp;-&nbsp;for the purpose of analysing the use of the
                                        platform and services improving our algorithms.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>in each case, in accordance with this privacy policy.</p><h2>Keeping Data secure</h2>
                        <ul>
                            <li>We will use technical and organisational measures to safeguard your Data, for
                                example:
                                <ul>
                                    <li>access to your account is controlled by a password and a user name that is
                                        unique to you.
                                    </li>
                                    <li>we store your Data on secure servers.</li>
                                    <li>payment details are encrypted using SSL technology (typically you will see a
                                        lock icon or green address bar (or both) in your browser when we use this
                                        technology.
                                    </li>
                                </ul>
                            </li>
                            <li>Technical and organisational measures include measures to deal with any suspected
                                data breach. If you suspect any misuse or loss or unauthorised access to your Data,
                                please let us know immediately by contacting us via this e-mail
                                address:&nbsp;Data@fundify.org.uk.
                            </li>
                            <li>If you want detailed information from Get Safe Online on how to protect your
                                information and your computers and devices against fraud, identity theft, viruses
                                and many other online problems, please visit www.getsafeonline.org. Get Safe Online
                                is supported by HM Government and leading businesses.
                            </li>
                        </ul>
                        <h2>Data retention</h2>
                        <ul>
                            <li>Unless a longer retention period is required or permitted by law, we will only hold
                                your Data on our systems for the period necessary to fulfil the purposes outlined in
                                this privacy policy or until you request that the Data be deleted.
                            </li>
                            <li>Even if we delete your Data, it may persist on backup or archival media for legal,
                                tax or regulatory purposes.
                            </li>
                        </ul>
                        <h2>Your rights</h2>
                        <ul>
                            <li>You have the following rights in relation to your Data:
                                <ul>
                                    <li><strong>Right to access</strong>&nbsp;- the right to request (i) copies of
                                        the information we hold about you at any time, or (ii) that we modify,
                                        update or delete such information. If we provide you with access to the
                                        information we hold about you, we will not charge you for this, unless your
                                        request is "manifestly unfounded or excessive." Where we are legally
                                        permitted to do so, we may refuse your request. If we refuse your request,
                                        we will tell you the reasons why.
                                    </li>
                                    <li><strong>Right to correct</strong>&nbsp;- the right to have your Data
                                        rectified if it is inaccurate or incomplete.
                                    </li>
                                    <li><strong>Right to erase</strong>&nbsp;- the right to request that we delete
                                        or remove your Data from our systems.
                                    </li>
                                    <li><strong>Right to restrict our use of your Data</strong>&nbsp;- the right to
                                        "block" us from using your Data or limit the way in which we can use it.
                                    </li>
                                    <li><strong>Right to data portability</strong>&nbsp;- the right to request that
                                        we move, copy or transfer your Data.
                                    </li>
                                    <li><strong>Right to object</strong>&nbsp;- the right to object to our use of
                                        your Data including where we use it for our legitimate interests.
                                    </li>
                                </ul>
                            </li>
                            <li>To make enquiries, exercise any of your rights set out above, or withdraw your
                                consent to the processing of your Data (where consent is our legal basis for
                                processing your Data), please contact us via this e-mail
                                address:&nbsp;Data@fundify.org.uk.
                            </li>
                            <li>If you are not satisfied with the way a complaint you make in relation to your Data
                                is handled by us, you may be able to refer your complaint to the relevant data
                                protection authority. For the UK, this is the Information Commissioner's Office
                                (ICO). The ICO's contact details can be found on their website at
                                https://ico.org.uk/.
                            </li>
                            <li>It is important that the Data we hold about you is accurate and current. Please keep
                                us informed if your Data changes during the period for which we hold it.
                            </li>
                        </ul>
                        <h2>Links to other websites</h2>
                        <ul>
                            <li>This Platform may, from time to time, provide links to other websites. We have no
                                control over such websites and are not responsible for the content of these
                                websites. This privacy policy does not extend to your use of such websites. You are
                                advised to read the privacy policy or statement of other websites prior to using
                                them.
                            </li>
                        </ul>
                        <h2>Changes of business ownership and control</h2>
                        <ul>
                            <li>Fundify Limited&nbsp;may, from time to time, expand or reduce our business and this
                                may involve the sale and/or the transfer of control of all or part of&nbsp;Fundify
                                Limited. Data provided by Users will, where it is relevant to any part of our
                                business so transferred, be transferred along with that part and the new owner or
                                newly controlling party will, under the terms of this privacy policy, be permitted
                                to use the Data for the purposes for which it was originally supplied to us.
                            </li>
                            <li>We may also disclose Data to a prospective purchaser of our business or any part of
                                it.
                            </li>
                            <li>In the above instances, we will take steps with the aim of ensuring your privacy is
                                protected.
                            </li>
                        </ul>
                        <h2>Cookies</h2>
                        <ul>
                            <li>This Website may place and access certain Cookies on your computer.&nbsp;Fundify
                                Limited&nbsp;uses Cookies to improve your experience of using the Website and to
                                improve our range of services. Fundify Limited&nbsp;has carefully chosen these
                                Cookies and has taken steps to ensure that your privacy is protected and respected
                                at all times.
                            </li>
                            <li>All Cookies used by this Website are used in accordance with current UK and EU
                                Cookie Law.
                            </li>
                            <li>Before the Website places Cookies on your computer, you will be presented with a
                                message bar requesting your consent to set those Cookies. By giving your consent to
                                the placing of Cookies, you are enabling&nbsp;Fundify Limited&nbsp;to provide a
                                better experience and service to you. You may, if you wish, deny consent to the
                                placing of Cookies; however certain features of the Platform may not function fully
                                or as intended, or at all.
                            </li>
                            <li>This Website may place the following Cookies:</li>
                        </ul>
                        <br /> <br />
                        <table>
                            <tbody>
                            <tr>
                                <td><p><strong>Type of Cookie</strong></p></td>
                                <td><p><strong>Purpose</strong></p></td>
                            </tr>
                            <tr>
                                <td><p>Strictly necessary cookies</p></td>
                                <td><p>These are cookies that are required for the operation of our Platform.
                                    They include, for example, cookies that enable you to log into secure areas
                                    of our website, use a shopping cart or make use of e-billing services.</p>
                                </td>
                            </tr>
                            <tr>
                                <td><p>Analytical/performance cookies</p></td>
                                <td><p>They allow us to recognise and count the number of visitors and to see
                                    how visitors move around our Platform when they are using it. This helps us
                                    to improve the way our Platform works, for example, by ensuring that users
                                    are finding what they are looking for easily.</p></td>
                            </tr>
                            <tr>
                                <td><p>Functionality cookies</p></td>
                                <td><p>These are used to recognise you when you return to our Platform. This
                                    enables us to personalise our content for you, greet you by name and
                                    remember your preferences (for example, your choice of language or
                                    region).</p></td>
                            </tr>
                            </tbody>
                        </table>
                        <br /> <br />
                        <ul>
                            <li>You can find a list of Cookies that we use in the Cookies Schedule.</li>
                            <li>You can choose to enable or disable Cookies in your internet browser. By
                                default, most internet browsers accept Cookies but this can be changed. For
                                further details, please consult the help menu in your internet browser.
                            </li>
                            <li>You can choose to delete Cookies at any time; however you may lose any
                                information that enables you to access the Platform more quickly and
                                efficiently including, but not limited to, personalisation settings.
                            </li>
                            <li>It is recommended that you ensure that your internet browser is up-to-date
                                and that you consult the help and guidance provided by the developer of your
                                internet browser if you are unsure about adjusting your privacy settings.
                            </li>
                            <li>For more information generally on cookies, including how to disable them,
                                please refer to aboutcookies.org. You will also find details on how to
                                delete cookies from your computer.
                            </li>
                        </ul>
                        <h2>General</h2>
                        <ul>
                            <li>You may not transfer any of your rights under this privacy policy to any
                                other person. We may transfer our rights under this privacy policy where we
                                reasonably believe your rights will not be affected.
                            </li>
                            <li>If any court or competent authority finds that any provision of this privacy
                                policy (or part of any provision) is invalid, illegal or unenforceable, that
                                provision or part-provision will, to the extent required, be deemed to be
                                deleted, and the validity and enforceability of the other provisions of this
                                privacy policy will not be affected.
                            </li>
                            <li>Unless otherwise agreed, no delay, act or omission by a party in exercising
                                any right or remedy will be deemed a waiver of that, or any other, right or
                                remedy.
                            </li>
                            <li>This Agreement will be governed by and interpreted according to the law
                                of&nbsp;England and Wales. All disputes arising under the Agreement will be
                                subject to the exclusive jurisdiction of the&nbsp;English and Welsh courts.
                            </li>
                        </ul>
                        <h2>Changes to this privacy policy</h2>
                        <ul>
                            <li>Fundify Limited&nbsp;reserves the right to change this privacy policy as we
                                may deem necessary from time to time or as may be required by law. Any
                                changes will be immediately posted on the Website and you are deemed to have
                                accepted the terms of the privacy policy on your first use of the Website
                                following the alterations. <br /> <br /> You may contact&nbsp;Fundify
                                Limited&nbsp;by email at&nbsp;Privacy@fundify.org.uk.
                            </li>
                        </ul>
                        <h2>Cookies</h2><p>Below is a list of the cookies that we use. We have tried to
                        ensure this is complete and up to date, but if you think that we have missed a
                        cookie or there is any discrepancy, please let us know.</p><p>Strictly necessary</p>
                        <p>We use the following strictly necessary cookies:</p><p>&nbsp;<br /> <br /></p>
                        <h2>TBC We are currently changing some of our platforms and haven't got an extensive list of the
                            cookies we will be using yet.</h2>

                        <br />
                    </div>
                </div>
            </section>
            <Footer />
            <FooterAlt />
        </React.Fragment>
    )
}

export default Privacy;