import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Privacy from "./Privacy";
import Terms from "./Terms";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
    cache: new InMemoryCache()
});

class Root extends React.Component {

    render() {
        return (
            <ApolloProvider client={client}>
                <BrowserRouter basename={'/'}>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Main} />
                        <Route path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy} />
                        <Route path={`${process.env.PUBLIC_URL}/terms`} component={Terms} />
                    </Switch>
                </BrowserRouter>
            </ApolloProvider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
