import React from 'react';
import {Col, Row} from 'reactstrap';

const partners = [
    {
        name: "Great British Entrepreneur 2018",
        image: "Great-British-Entrepreneur-Awards-2018-with-Starling-Bank.png",
        url: "https://www.greatbritishentrepreneurawards.com/news/2018-finalists-manchester/",
        background: '#fff'
    },
    {
        name: "Tech Nation Rising Star Awards",
        image: "tech-nation.png",
        url: "https://entrepreneursforum.net/news/member-news/ef-member-jeff-breen-has-been-awarded-the-tech-nations-rising-stars-city-award/",
        background: '#fff'
    },
]

class AwardsSection extends React.Component {

    render() {
        return (
            <React.Fragment>
                <section className="section" id="Awards">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Awards</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <div className="row">
                            {partners.map((partner, idx) => (
                                <div key={`${partner.name}-${idx}`} style={{background: partner.background, margin: '1.2em', padding: '1.2em'}}>
                                    <a href={partner.url} target="_blank" rel="noopener noreferrer"><img
                                        alt={partner.name}
                                        src={`./images/partners/${partner.image}`}
                                        width="180px" /></a>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default AwardsSection;