import React from 'react';
import {Col, Row} from 'reactstrap';

const partners = [
    {
        name: "Newcastle University",
        image: "Newcastle University Logo.png",
        url: "https://www.ncl.ac.uk/business-and-partnerships/expert-solutions/arrow/",
        background: '#fff'
    },
    {
        name: "Sunderland University",
        image: "sunderland_university_logo.png",
        url: "https://www.sunderland.ac.uk/",
        background: '#fff'
    },
    {
        name: "Aston University",
        image: "Aston University.svg",
        url: "https://www.aston.ac.uk/",
        background: '#fff'
    },
    {
        name: "Teeside University",
        image: "Teeside University Logo.jpg",
        url: "https://www.aston.ac.uk/",
        background: '#fff'
    },
    {
        name: "Cantabury Christ Church University",
        image: "Cantabury University Logo.png",
        url: "https://www.canterbury.ac.uk/",
        background: '#fff'
    },
    {
        name: "Brunel University",
        image: "brunel-logo-blue.png",
        url: "https://www.brunel.ac.uk/",
        background: '#fff'
    },
]

const worksWith = [
    {
        name: "Ignite",
        image: "ignite.jpg",
        url: "https://ignite.io/",
        background: '#fff'
    },
    {
        name: "Digital Catapult",
        image: "digital_catapult.svg",
        url: "https://www.digicatapult.org.uk/",
        background: '#fff'
    },
    {
        name: "National Innovation Center For Data",
        image: "National Innovation Center For Data.png",
        url: "https://www.nicd.org.uk/",
        background: '#fff'
    },
]

class PartnerSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            PlaneList: [{
                "name": "Community",
                "price": "FREE",
                "is_active": true,
                "benefits": [
                    "Charities Search",
                    "Grant Finding",
                    "Data from Multiple Sources",
                    "Limited to individuals"
                ]
            }, {
                "name": "Community+",
                "price": "£40",
                "is_active": false,
                "benefits": [
                    "Community Edition features plus:",
                    "Enhanced Data",
                    "Saved Searches",
                    "Advanced Personalisation",
                    "Notifications & Reminders",
                    "For organisations"
                ]
            },
                {
                    "name": "Premium",
                    "price": "£TBC",
                    "is_active": false,
                    "benefits": [
                        "Up coming Plan.",
                        "Community+ Edition features plus:",
                        "Access to additional data",
                        "Input on Product Roadmap"
                    ]
                }
            ]
        };
    }

    render() {
        return (
            <React.Fragment>
                <section className="section" id="partners">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Working with</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            {partners.map((partner, idx) => (
                                <div key={`${partner.name}-${idx}`} style={{background: partner.background, margin: '1.2em', padding: '1.2em'}}>
                                    <a href={partner.url} target="_blank" rel="noopener noreferrer"><img
                                        alt={partner.name}
                                        src={`./images/partners/${partner.image}`}
                                        width="180px" /></a>
                                </div>
                            ))}
                        </div>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            {worksWith.map((partner, idx) => (
                                <div key={`${partner.name}-${idx}`} style={{background: partner.background, margin: '1.2em', padding: '1.2em'}}>
                                    <a href={partner.url} target="_blank" rel="noopener noreferrer"><img
                                        alt={partner.name}
                                        src={`./images/partners/${partner.image}`}
                                        width="180px" /></a>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default PartnerSection;