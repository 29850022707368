import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="footer-bg">
                    <div className="container">
                        <Row>
                            <Col lg="5">
                                <div className="mb-5">
                                    <p className="text-uppercase text-dark footer-title mb-4">Company</p>
                                    <p className="mb-0 f-13"><span className="text-muted">
                                                            12 Bessemer Court Hownsgill Industrial Park Knitsley Lane,<br /> Consett,<br /> Co Durham, England,<br />
                                                            DH8 7BL</span></p>
                                    <div className="app-contact-desc text-muted pt-1">
                                        <p className="mb-0 info-title f-13">Email :</p>
                                        <p className="mb-0 f-13"><Link to=""
                                                                       className="text-muted">sales@fundify.org.uk</Link>
                                        </p>
                                    </div>
                                    {/*<p className="text-uppercase text-dark footer-title mb-4">MISSION</p>*/}
                                    {/*<p className="text-muted f-14">Fundify is a mission driven business helping to improve the lives of millions of people in need. Every subscription purchased by an organisation helps to keep the information for individuals free and helps to improve our community.*/}
                                    {/*    </p>*/}
                                    {/*<p className="text-muted f-14">We are planting a forest to minimise our carbon footprint and always try to give more then we take.</p>*/}
                                </div>
                            </Col>

                            <Col lg="7">
                                <Row>
                                    <Col  lg="4">
                                        <div className="mb-5">
                                            <p className="text-uppercase text-dark footer-title mb-4">support</p>
                                            <ul className="list-unstyled footer-sub-menu">
                                                <li className="f-14"><a rel="noopener noreferrer" target="_blank" href="http://status.wearefundify.org.uk/" className="text-muted">Service Status</a></li>
                                                <li className="f-14"><a rel="noopener noreferrer" target="_blank" href="https://fundify.freshdesk.com/" className="text-muted">Support Desk</a></li>
                                            </ul>
                                        </div>
                                    </Col>

                                    <Col lg="4">
                                        <div className="mb-5">
                                            <p className="text-uppercase text-dark footer-title mb-4">Legal</p>
                                            <ul className="list-unstyled footer-sub-menu">
                                                <li className="f-14"><Link to="/terms" className="text-muted">Terms & Conditions</Link></li>
                                                <li className="f-14"><Link to="/privacy" className="text-muted">Privacy Policy</Link></li>
                                                <li className="f-14"><Link to="#pricing" className="text-muted">Pricing</Link></li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col lg="4" >
                                        <div className="mb-5">
                                            <p className="text-uppercase text-dark footer-title mb-4">Socials</p>
                                            <ul className="follow-icon list-inline mt-32 mb-0">
                                                <li className="list-inline-item f-15"><a target="_blank"
                                                                                         rel="noopener noreferrer"
                                                                                         href="https://www.facebook.com/wearefundify"
                                                                                         className="social-icon text-muted"><i
                                                    className="mdi mdi-facebook"></i></a></li>
                                                &nbsp;
                                                <li className="list-inline-item f-15"><a target="_blank"
                                                                                         rel="noopener noreferrer"
                                                                                         href="https://twitter.com/wearefundify"
                                                                                         className="social-icon text-muted"><i
                                                    className="mdi mdi-twitter"></i></a></li>
                                                &nbsp;
                                                <li className="list-inline-item f-15"><a target="_blank"
                                                                                         rel="noopener noreferrer"
                                                                                         href="https://www.linkedin.com/company/wearefundify"
                                                                                         className="social-icon text-muted"><i
                                                    className="mdi mdi-linkedin"></i></a></li>
                                                &nbsp;
                                                <li className="list-inline-item f-15"><a target="_blank"
                                                                                         rel="noopener noreferrer"
                                                                                         href="https://www.instagram.com/wearefundify"
                                                                                         className="social-icon text-muted"><i
                                                    className="mdi mdi-instagram"></i></a></li>
                                                &nbsp;
                                                <li className="list-inline-item f-15"><a target="_blank"
                                                                                         rel="noopener noreferrer"
                                                                                         href="https://www.youtube.com/@we-are-fundify"
                                                                                         className="social-icon text-muted"><i
                                                    className="mdi mdi-youtube"></i></a></li>
                                            </ul>
                                            {/*<ul className="list-unstyled footer-sub-menu">*/}
                                            {/*    /!*<li className="f-14"><Link to="/" className="text-muted">Monitoring Grader</Link></li>*!/*/}
                                            {/*    /!*<li className="f-14"><Link to="/" className="text-muted">Job Opening</Link></li>*!/*/}
                                            {/*    /!*<li className="f-14"><Link to="/" className="text-muted">Customers</Link></li>*!/*/}
                                            {/*    <li className="f-14"><Link to="/privacy" className="text-muted">Privacy</Link></li>*/}
                                            {/*</ul>*/}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default Footer;
